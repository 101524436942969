import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from "gatsby-plugin-firebase";

export default function Index() {
  const [user, loading, error] = useAuthState(typeof window !== 'undefined' ? firebase.auth() : {});

  if(user && typeof window !== 'undefined') {
      navigate('/dashboard');
  } else if(!user && typeof window !== 'undefined') {
      navigate('/auth/login');
  }

  return <div />;
}
